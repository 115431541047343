import React from "react";
import styles from './Footer.module.css';
import { Link } from "react-router-dom";

function Footer({ routes, externalLinks }) {

    // Duplicate with header, please refactor
    const otherRoutes = []

    for (const { label, path } of routes) {
        otherRoutes.push(<li key={label}><Link to={path}><div className={styles.Text}>{label}</div></Link></li>)
    }

    for (const { label, path, image, imageHover } of externalLinks) {
        otherRoutes.push(
            <li key={label}>
                <a href={path} target="_blank" rel="noreferrer">
                    <img src={imageHover} alt={label} className={`${styles.SocialIcon} ${styles.HoverIcon}`} />
                    <img src={image} alt={label} className={styles.SocialIcon} />
                </a>
            </li>
        )
    }
    //end duplicate part

    return (
        <footer className={styles.FooterContainer}>
            {/* possible duplicate with header, please refactor */}
            <nav className={styles.Navbar}>
                <ul className={styles.Menu}>
                    {
                        otherRoutes
                    }
                </ul>
            </nav>
            {/* end duplicate part */}
            <p className={styles.CopyrightText}>
                © 2022 Sigma Cats
            </p>
        </footer>
    );
}

export default Footer