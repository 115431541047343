import App from './App';
import Home from './components/Home/Home';
import Sigmaverse from './components/Sigmaverse/Sigmaverse';
import Tree from './components/Tree/Tree';
import Roundtable from './components/Roundtable/Roundtable';
import Faq from './components/Faq/Faq';
import TreeModal from './components/Tree/TreeModal';
import RoundtableModal from './components/Roundtable/RoundtableModal';

// should routes be changed to context?
const routes = [
    {
        path: "/",
        element: <App />,
        children: [
            {
                label: 'Home',
                path: "",
                element: <Home />,
            },
            {
                label: "Sigmaverse",
                path: "sigmaverse",
                element: <Sigmaverse />
            },
            {
                label: 'Tree',
                path: "tree",
                element: <Tree />,
                children: [
                    {
                        path: "details",
                        element: <TreeModal />
                    }
                ]
            },
            {
                label: 'Roundtable',
                path: "roundtable",
                element: <Roundtable />,
                children: [
                    {
                        path: "details",
                        element: <RoundtableModal />
                    }
                ]
            },
            {
                label: 'Faq',
                path: "faq",
                element: <Faq />
            }
        ],
    },
]

const headerFooterRoutes = routes[0].children

export { routes, headerFooterRoutes }
