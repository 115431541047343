import * as React from 'react'
import styles from "./Faq.module.css";
import BudgetAllocationsPie from "./BudgetAllocationsPie.png";
import MultiSigmaCatsBackground from "./MultiSigmaCatsBackground.png"
import MultiSigmaCatsReel from "./MultiSigmaCatsReel.mp4";

const FaqContent = [
  {
    question: "What are the minting details?",
    answer: "TBA."
  },
  {
    question: "How can I become a Sigma Cat?",
    answer: <>Prove that you are a Sigma. As a start, be the early ones to signup to our waitlist and <a href="https://t.me/Sigmacatsnft" target="_blank" rel="noreferrer">join our Telegram.</a></>
  },
  {
    question: "What IP rights do Sigma Cats holders get?",
    answer: "Same bullshit as any other NFT project. Check out the Bored Ape ones for references."
  },
  {
    question: "What are the Sigma Rules?",
    answer: <>As the OG Sigmagrindset community stated: <br />
      Wake up. <br />
      Take a shit. <br />
      Eat breakfast. <br />
      Get out of bed."</>
  },
  {
    question: "Why is Sigma Cats launching in a bear market?",
    answer: "Because we don't let shit bother us."
  },
  {
    question: "My GF said that this NFT is stupid. What should I do?",
    answer: "Dump her. You don't want to be with a woman who doesn't understand Sigma Cat Grindset."
  },
  {
    question: "Is Sigma Cats Male Exclusive?",
    answer: "Obviously no. Have you ever heard about Sigma Girl Boss Grindset?"
  },
  {
    question: "Is Sigma Cats just a meme project?",
    answer: "Nope, our holders will become trillionaires, and we are creating an infrastructure to support Sigma Cats holders. Also, did I mention the NFT platform we are building for creators?"
  },
  {
    question: "What do I do if I become rich and famous from the Sigma Cats Grindset?",
    answer: <>I know it is hard to take in all at once, but <a href="https://www.wikihow.com/Handle-Fame" target="_blank" rel="noreferrer">here is a guide from wikiHow</a> on how to handle fame.</>
  },
]

const budgetAllocationsKeyContent = [
  {
    boxClassName: styles.BoxBlue,
    text: "10% Founding Team",
  },
  {
    boxClassName: styles.BoxRiver,
    text: "10% Artists",
  },
  {
    boxClassName: styles.BoxRed,
    text: "15% Marketing",
  },
  {
    boxClassName: styles.BoxOrange,
    text: "10% Community Management",
  },
  {
    boxClassName: styles.BoxLime,
    text: "20% Developers",
  },
  {
    boxClassName: styles.BoxJade,
    text: "20% Project Funds",
  },
  {
    boxClassName: styles.BoxPurple,
    text: "15% Business Development",
  }
]

const nftDistributionContent = [
  { amount: "44", text: "Burn (Don't Ask Why)" },
  { amount: "145", text: "OGs" },
  { amount: "88", text: "Treasury" },
  { amount: "88", text: "Sigma Commanders" },
  { amount: "88", text: "Peasants" },
  { amount: "4145", text: "Proven Sigmas" },
  { amount: "4145", text: "Sigma Warriors" },
]

function Faq() {
  return (
    <div className="MediumMarginBottom">
      <section className={styles.MintingDetailsSection}>
        <img src={MultiSigmaCatsBackground} className={styles.MultiSigmaCatsBackgroundImage} alt="Multi Sigma Cats Background" />
        <div className={styles.MintingDetailsTextPart}>
          <h2 className="ExtraLarge Left">
            Minting Details
          </h2>
          <p className="Left ContentSmallMarginTop">
            Sigma Cats is a collection of 8888 uniquely generated NFTs with fair randomization. The original NFTs serve to recruit and reward early adopters who choose to believe in us from the start. In addition to helping expand the Sigmaverse, holders can unlock benefits and privileges for our upcoming NFT platform for creators.
          </p>
          <p className="Left ContentSmallMarginTop">
            Chain: Ethereum<br />
            Number of NFTs : 8888<br />
            Mint Price & Date: TBA
          </p>
        </div>
        <video className={styles.MultiSigmaCatsReelVideo} autoPlay loop muted playsInline>
          <source src={MultiSigmaCatsReel} type="video/mp4" />
        </video>
      </section>

      <section className="MediumMarginSides">
        <h2 className="ContentMediumMarginTop ExtraLarge">Sigma Cats Budget Allocations</h2>
        <p className="ContentSmallMarginTop">
          Our coherent budget plan is structured around driving Sigma Cats to
          its long term goal as well as motivating the team through fair
          incentives.
        </p>
        <div className={styles.BudgetImagesContainer}>
          <img className={styles.BudgetAllocationsPieImage} src={BudgetAllocationsPie} alt="Budget Allocations Pie Chart" />
          <div className={styles.BudgetAllocationsKeyContainer}>
            <ul className={styles.BudgetAllocationsKeyList}>
              {budgetAllocationsKeyContent.map(({ boxClassName, text }) => (
                <li className={styles.BudgetAllocationsKeyItem}>
                  <div className={`${boxClassName} ${styles.SmallBox}`} />
                  {text}
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.LineDivider}/>

          <ul className={styles.NftDistributionList}>
            <li>
              <h3 className={`Yellow Medium ${styles.NftDistributionHeader}`}>
                Number of NFTs: 8888
              </h3>
            </li>
            {nftDistributionContent.map(({amount, text}) => (
              <li className={styles.NftDistributionItem}>
                <div>
                  {amount}
                </div>
                <div>
                  {text}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>

      <section className="MediumMarginSides">
        <h1 className="Italic ContentMediumMarginTop">FAQ</h1>
        <div className={`${styles.FaqItemContainer} ContentSmallMarginTop`}>
          {
            FaqContent.map(({ question, answer }) => (
              <details className={styles.FaqItem}>
                <summary>
                  <div className={styles.QuestionText}>
                    {question}
                  </div>
                  <i className={styles.Arrow} />
                </summary>
                <p>
                  {answer}
                </p>
              </details>
            ))
          }
        </div>
      </section>
    </div>
  );
}

export default Faq;
