import * as React from 'react'
import styles from './TreeModal.module.css';
import { useNavigate, useLocation } from 'react-router-dom';

function TreeModal() {

    const navigate = useNavigate();
    const location = useLocation();
    const { headerText, fullImage, description, reverseLayout, extra } = location.state || {}

    const closeModal = () => navigate(-1)

    const [zoom, setZoom] = React.useState(false)

    return (
        <>
            <div className={styles.BlurBackground} onClick={closeModal} />
            <section className={`${styles.ModalContentSection} Black`}>
                <div className={`${styles.HeaderPart} BabyDoll Uppercase`}>
                    <h2 className={`${styles.ModalMainHeader} Light`}>
                        {headerText}
                    </h2>
                    <h3 className={`${styles.BackHeader} Light`} onClick={closeModal}>
                        Back to Sigma Tree&nbsp;➜
                    </h3>
                </div>
                <div className={`${styles.DescriptionPart} ${reverseLayout ? "FlexRowReverse" : ""}`}>
                    <img src={fullImage} className={styles.fullImage} alt={`${headerText} Full`} onClick={() => setZoom(true)} />
                    <p className={`Left ${styles.DescriptionText}`}>
                        {description}
                    </p>
                </div>
                {
                    extra.divClassName ?
                        <div className={extra.divClassName}>
                            <img src={extra.leftImage} className={extra.leftClassName} alt={`${headerText} Extra Left`} />
                            <img src={extra.rightImage} className={extra.rightClassName} alt={`${headerText} Extra Right`} />
                        </div> :
                        <img src={extra.image} className={extra.className} alt={`${headerText} Extra`} />
                }
            </section>
            {zoom && <div className={styles.FadedBackground} onClick={() => setZoom(false)} />}
            {zoom && <img src={fullImage} className={styles.ZoomedImage} alt={`${headerText} Zoomed`} />}
        </>
    )
}

export default TreeModal