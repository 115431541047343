import * as React from 'react'
import styles from './Tree.module.css';
import { Link, Outlet, useLocation } from "react-router-dom";

import SigmaTree from './SigmaTree.png'
import LoungeBlack from './LoungeBlack.png'
import LoungeColor from './LoungeColor.png'
import LoungeFull from './LoungeFull.png'
import LoungeMobile from './LoungeMobile.png'
import LoungeExtraLeft from './LoungeExtraLeft.png'
import LoungeExtraRight from './LoungeExtraRight.png'
import RecoveryBlack from './RecoveryBlack.png'
import RecoveryColor from './RecoveryColor.png'
import RecoveryFull from './RecoveryFull.png'
import RecoveryMobile from './RecoveryMobile.png'
import RecoveryExtraLeft from './RecoveryExtraLeft.png'
import RecoveryExtraRight from './RecoveryExtraRight.png'
import SeedBlack from './SeedBlack.png'
import SeedColor from './SeedColor.png'
import SeedFull from './SeedFull.png'
import SeedMobile from './SeedMobile.png'
import SeedExtra from './SeedExtra.png'
import HallBlack from './HallBlack.png'
import HallColor from './HallColor.png'
import HallFull from './HallFull.png'
import HallMobile from './HallMobile.png'
import HallExtra from './HallExtra.png'
import TrainingBlack from './TrainingBlack.png'
import TrainingColor from './TrainingColor.png'
import TrainingFull from './TrainingFull.png'
import TrainingMobile from './TrainingMobile.png'
import TrainingExtra from './TrainingExtra.png'
import Mountain from './Mountain.png'
import Lines from './Lines.png'

// part the optimization we're unsure about
const fullImages = [LoungeFull, RecoveryFull, SeedFull, TrainingFull, HallFull]

const ZoneContent = [
    {
        blackImage: LoungeBlack,
        colorImage: LoungeColor,
        mobileImage: LoungeMobile,
        headerText: "The Lounge",
        className: styles.LoungePart,
        modalContent: {
            fullImage: LoungeFull,
            headerText: "The Lounge",
            description: "The Sigma Tree is a place for Sigma Cats to fulfill their greatest potential and overcome setbacks in life, all via sharing experiences and ideas, giving constructive feedback, enjoying sigma conversations and solving daily sigma problems. This is driven by an ambitious and inclusive community of Sigma Cats.",
            reverseLayout: true,
            extra: {
                divClassName: styles.LoungeExtrasPart,
                leftImage: LoungeExtraLeft,
                leftClassName: styles.LoungeExtraLeftImage,
                rightImage: LoungeExtraRight,
                rightClassName: styles.LoungeExtraRightImage,
            },
        }
    },
    {
        blackImage: RecoveryBlack,
        colorImage: RecoveryColor,
        mobileImage: RecoveryMobile,
        headerText: "Recovery Station",
        className: styles.RecoveryPart,
        modalContent: {
            fullImage: RecoveryFull,
            headerText: "Recovery Station",
            description: "The Recovery Station heals you from emotional setbacks that stop you from becoming a trillionaire. Psychological hurdles such as burnout, imposter syndrome, heart break and stress are amongst the most common symptoms of being a Sigma Cat. The Sigma Cat community will guide you and help each other out so that you can overcome all emotional setbacks.",
            reverseLayout: true,
            extra: {
                divClassName: styles.RecoveryExtrasPart,
                leftImage: RecoveryExtraLeft,
                leftClassName: styles.RecoveryExtraLeftImage,
                rightImage: RecoveryExtraRight,
                rightClassName: styles.RecoveryExtraRightImage,
            },
        }
    },
    {
        blackImage: SeedBlack,
        colorImage: SeedColor,
        mobileImage: SeedMobile,
        headerText: "The Seed",
        className: styles.SeedPart,
        modalContent: {
            fullImage: SeedFull,
            headerText: "The Seed",
            description: "We know most Sigma Cats are hustlers. Sometimes we need a place to share ideas and get constructive feedback. There's no such thing as a stupid idea so feel free to share your craziest idea. If your idea takes off we can proudly say your billion dollar idea started in this room.",
            reverseLayout: false,
            extra: {
                image: SeedExtra,
                className: styles.SeedExtraImage,
            }
        }
    },
    {
        blackImage: HallBlack,
        colorImage: HallColor,
        mobileImage: HallMobile,
        headerText: "Hall of Fame",
        className: styles.HallPart,
        modalContent: {
            fullImage: HallFull,
            headerText: "Hall of Fame",
            description: "The Hall of Fame is a room to recognize the best Sigma Cats community members. For those who are planning to be active and contribute, make sure you are prepared to handle fame.",
            reverseLayout: true,
            extra: {
                image: HallExtra,
                className: styles.HallExtraImage,
            }
        }
    },
    {
        blackImage: TrainingBlack,
        colorImage: TrainingColor,
        mobileImage: TrainingMobile,
        headerText: "Training Ground",
        className: styles.TrainingPart,
        modalContent: {
            fullImage: TrainingFull,
            headerText: "Training Ground",
            description: "The training ground is where you can learn how to win Sigma Battles. We will bring in experts to hold workshop sessions where you’ll hopefully learn many new skills.",
            reverseLayout: false,
            extra: {
                image: TrainingExtra,
                className: styles.TrainingExtraImage,
            }
        }
    }
]

function Tree() {
    const location = useLocation();
    const open = location.pathname === '/tree/details'

    // an optimization to make the modals load faster, still unsure about this
    React.useEffect(() => {
        for (const image of fullImages) {
            const imageElement = new Image();
            imageElement.src = image;
        }
    }, [])

    // modal scroll lock
    React.useEffect(() => {
        if (!open) {
            return
        }

        if (window.innerWidth > 1100) {
            document.body.style.overflowY = 'hidden';
            return () => {
                document.body.style.overflowY = 'unset';
            }
        }
    }, [open]);

    //scroll restoration only on mobile layouts repeated on roundtable
    React.useEffect(() => {
        if (window.innerWidth <= 1100 && open) {
            window.scrollTo(0, 0);
        }
    }, [open])

    return (
        <div className={styles.TreeBackgroundImage}>
            <section className={`${styles.MainSection} Black BabyDoll Uppercase ${open ? styles.HideOnMobile : ""}`}>
                <h1 className={`${styles.TreeTitle} Light`}>
                    Sigma Tree
                </h1>

                <img src={SigmaTree} className={styles.SigmaTreeImage} alt="Sigma Tree" />

                {ZoneContent.map(({ blackImage, colorImage, mobileImage, headerText, className, modalContent }) =>
                (
                    <div className={`${className} ${styles.ZonePart}`} key={headerText}>
                        <Link to='details' state={modalContent} preventScrollReset={true}>
                            <img src={colorImage} className={`${styles.ZoneImage} ${styles.HoverImage}`} alt={`${headerText} Color`} />
                            <img src={mobileImage} className={styles.MobileImage} alt={`${headerText} Mobile`} />
                        </Link>
                        <img src={blackImage} className={styles.ZoneImage} alt={`${headerText} Black`} />
                        <h2 className={`Light ${styles.ZoneHeading} ${headerText === "Training Ground" ? styles.TrainingHeading : ""}`}>
                            {headerText}
                        </h2>
                    </div>
                )
                )}

                <img src={Mountain} className={styles.MountainImage} alt="Mountain" />

                <img src={Lines} className={styles.LinesImage} alt="Lines" />

                <a href="https://t.me/Sigmacatsnft" target="_blank" rel="noreferrer">
                    <button className={`${styles.JoinButton} BabyDoll Uppercase White`}>
                        Join Telegram
                    </button>
                </a>
            </section>

            <Outlet />
        </div>
    )
}

export default Tree