import './App.css';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import DiscordIcon from './images/discord.png';
import OpenseaIcon from './images/opensea.png';
import TwitterIcon from './images/twitter.png';
import TelegramIcon from './images/telegram.png';
import DiscordIconHover from './images/discordhover.png';
import OpenseaIconHover from './images/openseahover.png';
import TwitterIconHover from './images/twitterhover.png';
import TelegramIconHover from './images/telegramhover.png';
import { headerFooterRoutes } from './routes'; //not sure if we should use context instead or not

const externalLinks = [
  {
    label: 'Opensea',
    path: 'https://twitter.com/sigmacatsnft',
    image: OpenseaIcon,
    imageHover: OpenseaIconHover,
  },
  {
    label: 'Telegram',
    path: 'https://t.me/Sigmacatsnft',
    image: TelegramIcon,
    imageHover: TelegramIconHover,
  },
  {
    label: 'Twitter',
    path: 'https://twitter.com/sigmacatsnft',
    image: TwitterIcon,
    imageHover: TwitterIconHover,
  },
]

function App() {
  return (
    <div>
      <Header routes={headerFooterRoutes} externalLinks={externalLinks} />
      <div className="MainBackground">
        <Outlet />
      </div>
      <Footer routes={headerFooterRoutes} externalLinks={externalLinks} />
      <ScrollRestoration />
    </div>
  );
}

export default App;
