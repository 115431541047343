import * as React from 'react'
import styles from './Roundtable.module.css';
import { Link, Outlet, useLocation } from "react-router-dom";

import RoundtableBackground from './RoundtableBackground.jpg'
import MobileRoundtableBackground from './MobileRoundtableBackground.jpg'
import SparklyLights from './SparklyLights.png'
import GlobeDark from './GlobeDark.png'
import GlobeLight from './GlobeLight.png'
import BlueStars from './BlueStars.png'
import GlobeLongText from './GlobeLongText.png'
import KnightDark from './KnightDark.png'
import KnightLight from './KnightLight.png'
import YellowStars from './YellowStars.png'
import KnightLongText from './KnightLongText.png'
import ChairDark from './ChairDark.png'
import ChairLight from './ChairLight.png'
import OrangeStars from './OrangeStars.png'
import ChairLongText from './ChairLongText.png'
import CatDark from './CatDark.png'
import CatLight from './CatLight.png'
import RedStars from './RedStars.png'
import CatLongText from './CatLongText.png'
import FlagDark from './FlagDark.png'
import FlagLight from './FlagLight.png'
import PurpleStars from './PurpleStars.png'
import FlagLongText from './FlagLongText.png'
import TreeDark from './TreeDark.png'
import TreeLight from './TreeLight.png'
import GreenStars from './GreenStars.png'
import Light from './Light.png'

const PieceContent = [
    {
        pieceName: "Globe",
        darkImage: GlobeDark,
        lightImage: GlobeLight,
        imageClassName: styles.GlobeImage,
        lightClassName: styles.LightOnGlobeImage,
        modalContent:
        {
            headerText: "Web 3.0",
            lightImage: GlobeLight,
            description: "Our Web 3.0 Project",
            starsImage: BlueStars,
            descriptionClassName: styles.GlobeModalDescription,
            longText: GlobeLongText,
        }
    },
    {
        pieceName: "Knight",
        darkImage: KnightDark,
        lightImage: KnightLight,
        imageClassName: styles.KnightImage,
        lightClassName: styles.LightOnKnightImage,
        modalContent:
        {
            headerText: "Knighthood",
            lightImage: KnightLight,
            description: "Revolutionary Grindset",
            starsImage: YellowStars,
            descriptionClassName: styles.KnightModalDescription,
            longText: KnightLongText,
        }
    },
    {
        pieceName: "Chair",
        darkImage: ChairDark,
        lightImage: ChairLight,
        imageClassName: styles.ChairImage,
        lightClassName: styles.LightOnChairImage,
        hoverClassName: styles.ChairHover,
        modalContent:
        {
            headerText: "Utopia",
            lightImage: ChairLight,
            description: "Road to Sigmaverse",
            starsImage: OrangeStars,
            descriptionClassName: styles.ChairModalDescription,
            longText: ChairLongText,
        }
    },
    {
        pieceName: "Cat",
        darkImage: CatDark,
        lightImage: CatLight,
        imageClassName: styles.CatImage,
        lightClassName: styles.LightOnCatImage,
        modalContent:
        {
            headerText: "Sigma Cats",
            lightImage: CatLight,
            description: "The only NFTs you can grind with",
            starsImage: RedStars,
            descriptionClassName: styles.CatModalDescription,
            longText: CatLongText,
        }
    },
    {
        pieceName: "Flag",
        darkImage: FlagDark,
        lightImage: FlagLight,
        imageClassName: styles.FlagImage,
        lightClassName: styles.LightOnFlagImage,
        hoverClassName: styles.FlagHover,
        modalContent:
        {
            headerText: "Visionary",
            lightImage: FlagLight,
            description: "Sustainable Brand Building",
            starsImage: PurpleStars,
            descriptionClassName: styles.FlagModalDescription,
            longText: FlagLongText,
        }
    },
    {
        pieceName: "Tree",
        darkImage: TreeDark,
        lightImage: TreeLight,
        imageClassName: styles.TreeImage,
        lightClassName: styles.LightOnTreeImage,
        modalContent:
        {
            headerText: "Sigma Tree",
            lightImage: TreeLight,
            description: "The Vibrant Community",
            starsImage: GreenStars,
            descriptionClassName: styles.TreeModalDescription,
            longText: CatLongText,
        }
    },
]

function Roundtable() {

    const location = useLocation();
    const open = location.pathname === '/roundtable/details'

    // modal scroll lock
    React.useEffect(() => {
        if (!open) {
            return
        }

        if (window.innerWidth > 1100) {
            document.body.style.overflowY = 'hidden';
            return () => {
                document.body.style.overflowY = 'unset';
            }
        }
    }, [open]);

    //scroll restoration only on mobile layouts repeated on tree
    React.useEffect(() => {
        if (window.innerWidth <= 1100 && open) {
            window.scrollTo(0, 0);
        }
    }, [open])

    return (
        <>
            <section className={`${styles.MainSection} ${open ? styles.HideOnMobile : ""}`}>
                <img src={RoundtableBackground} className={styles.RoundtableBackgroundImage} alt="Roundtable Background" />
                <img src={MobileRoundtableBackground} className={styles.MobileRoundtableBackgroundImage} alt="Mobile Roundtable Background" />
                <img src={SparklyLights} className={styles.SparklyLightsImage} alt="Sparkly Lights" />

                {/* the classes have to be applied to the link in order to use the sibling css selector for the hover and light shine */}
                {PieceContent.map(({ pieceName, darkImage, lightImage, imageClassName, lightClassName, hoverClassName, modalContent }) => (
                    <>
                        <Link to='details' state={modalContent} className={`${imageClassName} ${styles.HoverImage} ${hoverClassName ? hoverClassName : ""}`} preventScrollReset={true}>
                            <img src={lightImage} alt={`${pieceName} Light`} />
                        </Link>
                        <img src={darkImage} className={imageClassName} alt={`${pieceName} Dark`} />
                        <img src={Light} className={`${lightClassName} ${styles.Light}`} alt={`Light On ${pieceName}`} />
                    </>
                ))}
            </section>
            <Outlet />
        </>
    )
}

export default Roundtable