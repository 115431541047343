import * as React from 'react'
import styles from './Sigmaverse.module.css';
import { Link } from "react-router-dom";

import SigmaCatHoldingEarth from './SigmaCatHoldingEarth.mp4'
import SpaceBackground from './SpaceBackground.jpg'
import MountainCats from './MountainCats.png'
import EmbraceSigmaGrindset from './EmbraceSigmaGrindset.png'
import SigmaGrindsetBackground from './SigmaGrindsetBackground.png'
import SigmaGrindsetMobile from './SigmaGrindsetMobile.png'
import DoubleQuote from './DoubleQuote.png'
import AvatarGold from './AvatarGold.png'
import AvatarBlack from './AvatarBlack.png'
import AvatarOrange from './AvatarOrange.png'
import FiveStars from './FiveStars.png'
import FourPointFiveStars from './FourPointFiveStars.png'

const TestimonialsContent = [
    {
        testimonial: "20 days in, and I can mine Bitcoin with my subconscious—five Stars project",
        name: "Sigma Cat OG Member.",
        avatar: AvatarGold,
        stars: FiveStars,
    },
    {
        testimonial: <><p>Wish I'd known the Sigma Cat Grindset earlier because as soon as I joined, I grew a mustache and became a Navy Seal. My mom fainted, and my dad shed manly tears. I was born a girl.</p> <p className={styles.TinyMarginTop}>Minus 0.5 stars because my breast was really nice.</p></>,
        name: "Sigma Cat Member #0018",
        avatar: AvatarOrange,
        stars: FourPointFiveStars,
    },
    {
        testimonial: "The Sigma Cats community told me not to let shit bother me, so now I've stopped wiping my ass.",
        name: "Sigma Cat Member",
        avatar: AvatarBlack,
        stars: FiveStars,
    },
]

function Sigmaverse() {
    return (
        <div className="MediumMarginBottom">
            <section className={styles.HeroSection}>
                <div className={styles.IntroductionPart}>
                    <div className={`${styles.IntroductionTextPart} Left`}>
                        <h1 className="Yellow Uppercase ExtraExtraLarge">
                            Sigmaverse
                        </h1>
                        <p className="ContentSmallMarginTop">
                            Our concept of the Sigmaverse is to create a community, and a brand infrastructure to support Sigma Cats NFT holders, allowing holders to embrace the Sigma Cat Grindset.
                        </p>
                        <div className={`${styles.TextPartLinks} ${styles.LargerTextMarginTop}`}>
                            {/* refactor this shit to use context */}
                            <Link to="/roundtable">Roundtable&nbsp;&nbsp;→</Link>
                            <Link to="/faq">FAQ&nbsp;&nbsp;→</Link>
                        </div>
                    </div>
                    <video className={styles.SigmaCatHoldingEarthVideo} autoPlay loop muted playsInline>
                        <source src={SigmaCatHoldingEarth} type="video/mp4" />
                    </video>
                </div>

                <div className={styles.ExplanationPart}>
                    <img src={MountainCats} className={styles.MountainCatsImage} alt="Mountain Cats" />
                    <div className={`${styles.ExplanationTextPart} Left`}>
                        <h2 className="Yellow ExtraLarge">
                            What is a Sigma Cat?
                        </h2>
                        <p className='ContentSmallMarginTop'>
                            Let’s preface this by explaining the three different types of Cats.
                        </p>
                        <p className={styles.LargerTextMarginTop}>
                            The Alphas <br /> Your so called “leaders”, the Alphas live day in day out to spit on and order around the Betas.                        </p>
                        <p className='ContentSmallMarginTop'>
                            The Betas <br /> No matter how badly the Alphas treat them, the Betas still constantly try to suck up to and impress the Alphas, hoping to one day be like them. However, most are stuck as Betas their whole life.                        </p>
                        <p className='ContentSmallMarginTop'>
                            The Sigmas <br /> The rarest 0.01% of all cats, the Sigma Cats have very little regard for the traditional hierarchy of cats. They’ll grind in secret, one day changing the world, becoming trillionaires, and most importantly becoming the best version of themselves, not what society expects them to be.
                        </p>
                        <div className={`${styles.TextPartLinks} ${styles.LargerTextMarginTop} ${styles.ExplanationTextPartLink}`}>
                            {/* refactor this shit to use context */}
                            <Link to="/tree">Sigma Tree&nbsp;&nbsp;→</Link>
                        </div>
                    </div>
                </div>
                <img src={SpaceBackground} className={styles.SpaceBackgroundImage} alt="Space Background" />
            </section>

            <section className={styles.GrindsetSection}>
                <div className={`${styles.GrindsetTextPart} Left`}>
                    <h2 className={`Yellow ExtraLarge ${styles.GrindsetHeader}`}>
                        Sigma Cat Grindset
                    </h2>
                    <div>
                        <h2 className="ContentSmallMarginTop">
                            Enables You To
                        </h2>
                        <ul className="ContentSmallMarginTop">
                            <li>
                                Stay focused on your goals.
                            </li>
                            <li>
                                Do what truly matters to you.
                            </li>
                            <li>
                                Kill 2 stones with 1 bird.
                            </li>
                            <li>
                                Become a trillionaire.
                            </li>
                            <li>
                                Understand the origin of the universe.
                            </li>
                        </ul>
                    </div>
                    <img className={`${styles.EmbraceSigmaGrindsetImage} ContentSmallMarginTop`} src={EmbraceSigmaGrindset} alt="Embrace Sigma Grindset" />
                </div>
                <img src={SigmaGrindsetBackground} className={styles.SigmaGrindsetBackgroundImage} alt="Sigma Grindset Background" />
                <img src={SigmaGrindsetMobile} className={styles.SigmaGrindsetMobileImage} alt="Sigma Grindset Mobile" />
            </section>

            <section className={`MediumMarginSides`}>
                <h2 className="Yellow ExtraLarge ContentMediumMarginTop">
                    Testimonials
                </h2>
                <ul className={`${styles.TestimonialsList}`}>
                    {TestimonialsContent.map(({ testimonial, name, avatar, stars }) => (
                        <li>
                            <div>
                                <img className={`${styles.DoubleQuoteImage}`} src={DoubleQuote} alt="Double Quote" />
                                <p className={styles.TinyMarginTop}>
                                    {testimonial}
                                </p>
                            </div>
                            <div className={`${styles.AvatarPart} ${styles.TinyMarginTop}`}>
                                <img className={`${styles.AvatarImage}`} src={avatar} alt={`${name} Avatar`} />
                                <div className={styles.NameAndStarsPart}>
                                    <h4 className='Yellow'>
                                        {name}
                                    </h4>
                                    <img className={`${styles.StarsImage}`} src={stars} alt={`${name} Stars`} />
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </section>

        </div>
    )
}

export default Sigmaverse