import * as React from 'react'
import styles from './RoundtableModal.module.css';
import { useNavigate, useLocation } from 'react-router-dom';

function RoundtableModal() {

    const navigate = useNavigate();
    const location = useLocation();
    const { headerText, description, lightImage, starsImage, descriptionClassName, longText } = location.state || {}

    const closeModal = () => navigate(-1)

    return (
        <>
            {/* repeated with tree? refactor? */}
            <div className={styles.FadedBackground} onClick={closeModal} />

            <section className={`${styles.ModalContentSection}`}>
                <img src={lightImage} className={styles.LightImage} alt={`${headerText} Light`} />

                <div className={styles.TextPart}>
                    <div className={styles.SpaceBetween}>
                        <h2 className={styles.ModalMainHeader}>
                            {headerText}
                        </h2>
                        <h3 className={styles.BackHeader} onClick={closeModal}>
                            Back to Roundtable&nbsp;&nbsp;➜
                        </h3>
                    </div>

                    <div className={`${styles.SpaceBetween} ${styles.DescriptionPart}`}>
                        <h3 className={`${descriptionClassName} ${styles.DescriptionText}`}>
                            {description}
                        </h3>
                        <img src={starsImage} className={styles.starsImage} alt="Three Stars" />
                    </div>

                    <img src={longText} className={styles.LongTextImage} alt="Coming Soon" />
                </div>
            </section>
        </>
    )
}

export default RoundtableModal