import * as React from 'react'
import styles from './Home.module.css';
import { Link } from "react-router-dom";
import MailchimpSubscribe from "react-mailchimp-subscribe";

import SigmaTree from "./SigmaTree.png";
import GetPastSecurity from "./GetPastSecurity.png";
import GetPastSecurityHover from './GetPastSecurityHover.png'
import RoundtableLink from './RoundtableLink.png';
import RoundtableLinkHover from './RoundtableLinkHover.png'
import TrailerLink from './TrailerLink.png';
import TrailerLinkHover from './TrailerLinkHover.png'
import SigmaverseLink from './SigmaverseLink.png';
import SigmaverseLinkHover from './SigmaverseLinkHover.png'

import PJ from "./PJ.png"
import Tan from "./Tan.png"
import Nikki from "./Nikki.png";
import Guy from "./Guy.png";
import Stickman from "./Stickman.png";
import AdamAnakin from "./AdamAnakin.png";
import PukPuy from "./PukPuy.png";
import Pin from "./Pin.png";
import ScarletSeen from "./ScarletSeen.png";
import iTAE from "./iTAE.png";
import Pee from './Pee.png';
import Non from './Non.png';

const TeamContent = [
    {
        name: "PJ",
        role: "Sigma Grindset OG",
        image: PJ
    },
    {
        name: "Tan",
        role: "Based Dev",
        image: Tan
    },
    {
        name: "Nikki",
        role: "Draws Too Much",
        image: Nikki
    },
    {
        name: "Pin",
        role: "Girl Boss",
        image: Pin
    },
    {
        name: "Stickman",
        role: "Graphic Designer",
        image: Stickman
    },
    {
        name: "Puk Puy",
        role: "Marketing Guru",
        image: PukPuy
    },
    {
        name: "iTAE",
        role: "Trillionaire Rapper",
        image: iTAE
    },
    {
        name: "Scarlet Seen",
        role: "Lore Master",
        image: ScarletSeen
    },
    {
        name: "Adam Anakin",
        role: "Comic Artist",
        image: AdamAnakin
    },
    {
        name: "Guy",
        role: "Smart Guy",
        image: Guy
    },
    {
        name: "Pee",
        role: "Based Dev 2.0",
        image: Pee
    },
    {
        name: "Non",
        role: "Level 80 Dev",
        image: Non
    },
]

const CustomForm = ({ status, message, onValidated }) => {

    const [email, setEmail] = React.useState('');

    React.useEffect(() => {
        if (status === "success") setEmail('');
    }, [status])

    const onSubmit = (e) => {
        e.preventDefault()
        email &&
            email.indexOf("@") > -1 &&
            onValidated({
                EMAIL: email,
            });
    }

    return (
        <>
            <form onSubmit={onSubmit} className={`${styles.SubscribeInputAndButton} ${status=== "success"? "Hide": ""}`}>
                <input value={email} onChange={(e) => setEmail(e.target.value)} type="text"
                    className={styles.SubscribeInput} placeholder="ENTER EMAIL" size="22" />

                <button className={`${styles.SubscribeButton}`} type='submit'>
                    {status === "sending" ? <span className={styles.Spinner} >Join Waitlist</span> : "Join Waitlist"}
                </button>
            </form>
            {
                status === "success" &&
                <div className='Green'>
                    {message}
                </div>
            }
            {
                status === "error" &&
                <div className='Red'>
                    {message}
                </div>
            }
        </>
    );
};

function MailchimpFormContainer(props) {

    const postUrl = `https://gmail.us14.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`

    return (
        <MailchimpSubscribe
            url={postUrl}
            render={({ subscribe, status, message }) => (
                <CustomForm
                    status={status}
                    message={message}
                    onValidated={formData => subscribe(formData)}
                />
            )}
        />
    )
}

function Home() {
    return (
        <div className="MediumMarginTop MediumMarginSides MediumMarginBottom">
            <section className={styles.SigmaTreeSection}>
                <img className={styles.SigmaTreeImage} src={SigmaTree} alt="Sigma Tree" />
                <Link to="/tree">
                    <img className={`${styles.GetPastSecurityImage} ${styles.HoverImage} ${styles.GetPastSecurityImageHover}`} src={GetPastSecurityHover} alt="Get Past Security Hover" />
                    <img className={`${styles.GetPastSecurityImage} ${styles.UnderImage}`} src={GetPastSecurity} alt="Get Past Security" />
                </Link>
            </section>

            <section className={`${styles.SubscribeSection} ContentMediumMarginTop`}>
                <h2 className="Yellow Uppercase">
                    Be the first to know
                </h2>
                <h3>
                    To Spark Greatness in Every Sigma
                </h3>
                <MailchimpFormContainer />
            </section>

            <section className={`${styles.TeamAndPitchSection} ContentMediumMarginTop`}>
                <ul className={styles.TeamList}>
                    {TeamContent.map(({ name, role, image }) => (
                        <li>
                            <img src={image} alt={name} className={styles.TeamMemberImage} />
                            <h5 className={`Small Normal Left ${styles.TeamMemberName}`}>
                                {name}
                            </h5>
                            <p className={`Small Normal Italic Left ${styles.TeamMemberRole}`}>
                                {role}
                            </p>
                        </li>
                    ))}
                </ul>

                <div className={styles.PitchPart}>
                    <h1 className={`Left`}>
                        NFTs for Sigmas
                    </h1>
                    <p className={`Left ContentSmallMarginTop`}>
                        What better unifying way to do this than to make a community based on some cute AF NFTs. We've designed each Sigma Cat to embody the Sigma personality, each one can represent you in our community.
                    </p>
                    <Link to="/roundtable">
                        <img className={`ContentSmallMarginTop ${styles.RoundtableLinkImage} ${styles.HoverImage} ${styles.RoundtableLinkImageHover}`} src={RoundtableLinkHover} alt="Roundtable Link Hover" />
                        <img className={`ContentSmallMarginTop ${styles.RoundtableLinkImage} ${styles.UnderImage}`} src={RoundtableLink} alt="Roundtable Link" />
                    </Link>
                    <h2 className={`Left ExtraLarge ContentSmallMarginTop`}>
                        The OG Sigmas
                    </h2>
                    <p className={`Left ContentSmallMarginTop`}>
                        The Sigma Grindset movement has been catching on like wildfire. Ironic or non-ironic, quite frankly we don’t care. There has yet to be a tightly knit community of Sigmas, working together to achieve greatness. The Sigmas are dispersed all over the internet, on a few YouTube videos and here and there, on some niche subreddits, etc. We see great potential in creating a unifying Sigma Society.
                    </p>
                    <div className={`${styles.TrailerSigmaverseLinksContainer} ContentSmallMarginTop`}>
                        <div>
                            <Link to="#">
                                <img className={`${styles.TrailerLinkImage} ${styles.HoverImage} ${styles.TrailerLinkImageHover}`} src={TrailerLinkHover} alt="Trailer Link Hover" />
                                <img className={`${styles.TrailerLinkImage} ${styles.UnderImage}`} src={TrailerLink} alt="Trailer Link" />
                            </Link>
                        </div>
                        <div>
                            <Link to="/sigmaverse">
                                <img className={`${styles.SigmaverseLinkImage} ${styles.HoverImage} ${styles.SigmaverseLinkImageHover}`} src={SigmaverseLinkHover} alt="Sigmaverse Link Hover" />
                                <img className={`${styles.SigmaverseLinkImage} ${styles.UnderImage}`} src={SigmaverseLink} alt="Sigmaverse Link" />
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Home