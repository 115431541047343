import React from "react";
import { Link } from "react-router-dom";

import styles from './Header.module.css';
import SigmaLogo from '../images/sigmalogo.png'
import SigmaLogoHover from '../images/sigmalogohover.png'
import MobileHeaderSigmaCat from '../images/MobileHeaderSigmaCat.png'

function Header({ routes, externalLinks }) {

    const [checked, setChecked] = React.useState(false)
    const routeChanged = React.useRef(false)
    const menu = React.useRef(null);

    const uncheckAfterLinkClicked = () => {
        if (checked) {
            setChecked(false);
            routeChanged.current = true;
        }
    }

    React.useEffect(() => {
        if (!checked) {
            return
        }

        // reset scroll of the menu of the header, nothing to do with scroll lock
        menu.current.scrollTop = 0

        const scrollPosition = window.scrollY
        const bodyStyle = document.body.style

        bodyStyle.overflowY = 'hidden';
        bodyStyle.position = 'fixed';
        bodyStyle.top = `-${scrollPosition}px`;
        bodyStyle.width = '100%'

        return () => {
            bodyStyle.overflowY = 'unset';
            bodyStyle.position = 'unset'
            bodyStyle.top = 'unset'
            bodyStyle.width = 'unset'
            if (!routeChanged.current) {
                window.scrollTo(0, scrollPosition);
            } else {
                window.scrollTo(0, 0);
                routeChanged.current = false
            }
        }
    }, [checked]);

    const toggleChecked = () => setChecked(checked => !checked)

    let homeRoute = null
    const navRoutes = []
    const socialRoutes = []

    for (const { label, path } of routes) {
        if (label === "Home") {
            homeRoute = (
                <Link to={path} onClick={uncheckAfterLinkClicked}>
                    <img src={SigmaLogoHover} className={`${styles.HoverIcon} ${styles.Logo}`} alt="SigmaLogoHome" />
                    <img src={SigmaLogo} className={styles.Logo} alt="SigmaLogoHome" />
                </Link>)
        }
        navRoutes.push(
            <li key={label} >
                <Link to={path} onClick={uncheckAfterLinkClicked}>
                    <div className={styles.Text}  >
                        {label}
                    </div>
                </Link>
            </li>
        )
    }

    for (const { label, path, image, imageHover } of externalLinks) {
        socialRoutes.push(
            <li key={label}>
                <a href={path} target="_blank" rel="noreferrer">
                    <img src={imageHover} alt={label} className={`${styles.SocialIcon} ${styles.HoverIcon}`} />
                    <img src={image} alt={label} className={styles.SocialIcon} />
                </a>
            </li>
        )
    }

    return (
        <header className={`${styles.Header} ${checked ? styles.Active : ""}`}>
            <nav className={styles.Navbar}>
                <div className={styles.LogoContainer} >
                    {homeRoute}
                </div>

                <div className={styles.MobileHamburger} onClick={toggleChecked}>
                    <span className={`${styles.Line} ${styles.LineTop}`}></span>
                    <span className={`${styles.Line} ${styles.LineMiddle}`}></span>
                    <span className={`${styles.Line} ${styles.LineBottom}`}></span>
                </div>

                <div className={styles.Menu} ref={menu}>
                    <ul className={`${styles.Links} ${styles.NavLinks}`}>
                        {
                            navRoutes
                        }
                    </ul>
                    <ul className={styles.Links}>
                        {
                            socialRoutes
                        }
                    </ul>
                    <img src={MobileHeaderSigmaCat} className={styles.MobileHeaderSigmaCat} alt="Mobile Header Sigma Cat" />
                </div>
            </nav>
            <div className={styles.FadedBackground} onClick={() => setChecked(false)} />
        </header>
    );
}

export default Header